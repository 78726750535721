// Here you can add other styles
* {
  font-family: 'DM Sans', sans-serif !important;
}

html body {
  font-size: 0.875rem;
  font-family: 'DM Sans', -apple-system, BlinkMacSystemFont, 'Roboto', 'Helvetica Neue', sans-serif !important;
}

.c-sidebar-nav-link {
  white-space: normal !important;
}
